import React, { useState } from 'react';
import countryList from './countryList.json'; // Adjust the path as needed
import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import { client } from '../../client';
import './Inscription.scss';

const Inscription = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    nationality: '',
    countryOfResidence: '',
    email: '',
    phoneNumber: '',
    university: '',
    major: '',
    essay: '',
    motivation: '',
    cv: '',
    otherDocument: '',
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { firstName, lastName, dateOfBirth, nationality, countryOfResidence, email, phoneNumber, university, major, essay, motivation } = formData;

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async () => {
    setLoading(true);

    if (firstName.trim() === ''
    || lastName.trim() === ''
    || dateOfBirth.trim() === ''
    || nationality.trim() === ''
    || countryOfResidence.trim() === ''
    || email.trim() === ''
    || phoneNumber.trim() === ''
    || university.trim() === ''
    || major.trim() === ''
    || essay.trim() === ''
    || motivation.trim() === ''
    ) {
      alert('Please fill in all required fields.');
      return;
    }

    try {
      // Upload CV if it exists
      let cvReference;
      if (formData.cv) {
        const cvUpload = await client.assets.upload('file', formData.cv, {
          filename: formData.cv.name,
        });
        cvReference = {
          _type: 'file',
          asset: {
            _type: 'reference',
            _ref: cvUpload._id,
          },
        };
      }

      // Upload otherDocument if it exists
      let otherDocumentReference;
      if (formData.otherDocument) {
        const otherDocumentUpload = await client.assets.upload('file', formData.otherDocument, {
          filename: formData.otherDocument.name,
        });
        otherDocumentReference = {
          _type: 'file',
          asset: {
            _type: 'reference',
            _ref: otherDocumentUpload._id,
          },
        };
      }

      if (!formData.cv) {
        alert('Please upload your CV.');
        setLoading(false);
        return;
      }

      // Email format validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        alert('Please enter a valid email address.');
        return;
      }

      // Phone number format validation (Lebanese or French format)
      const phoneRegex = /^(\+961 ?\d{1,8}|\+33 ?\d{1,10})$/;
      if (!phoneRegex.test(phoneNumber)) {
        alert('Please enter a phone number in Lebanese (+961) or French (+33) format.');
        return;
      }

      const inscription = {
        _type: 'inscription',
        firstName: formData.firstName,
        lastName: formData.lastName,
        dateOfBirth: formData.dateOfBirth,
        nationality: formData.nationality,
        countryOfResidence: formData.countryOfResidence,
        email: formData.email,
        phoneNumber: formData.phoneNumber,
        university: formData.university,
        major: formData.major,
        essay: formData.essay,
        motivation: formData.motivation,
        cv: cvReference,
        otherDocument: otherDocumentReference,
      };

      await client.create(inscription);
      setIsFormSubmitted(true);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <h2 className="head-text">Inscription Form</h2>
      <div className="app__inscription-cards">
        <div className="app__inscription-card ">
          <img src={images.email} alt="email" />
          <a href="mailto:info@upliftmentorship.pro" className="p-text">More Informations?</a>
        </div>
        <div className="app__inscription-card">
          <img src={images.mobile} alt="phone" />
          <a href="tel:+1 (123) 456-7890" className="p-text">Contact US !</a>
        </div>
      </div>
      {!isFormSubmitted ? (
        <div className="app__inscription-form app__flex">
          <label htmlFor="firstName">
            First Name <span style={{ color: 'red' }}>*</span>
            <div className="app__flex">
              <input className="p-text" type="text" placeholder="First Name" id="firstName" name="firstName" value={firstName} onChange={handleChangeInput} required maxLength="50" />
            </div>
          </label>
          <label htmlFor="lastName">
            Last Name <span style={{ color: 'red' }}>*</span>
            <div className="app__flex">
              <input className="p-text" type="text" placeholder="Last Name" id="lastName" name="lastName" value={lastName} onChange={handleChangeInput} required maxLength="50" />
            </div>
          </label>
          <label htmlFor="dateOfBirth">
            Date of Birth <span style={{ color: 'red' }}>*</span>
            <div className="app__flex">
              <input className="p-text" type="date" placeholder="Date of Birth" id="dateOfBirth" name="dateOfBirth" value={dateOfBirth} max="2007-01-01" onChange={handleChangeInput} required />
            </div>
          </label>
          <label htmlFor="email">
            Your Email <span style={{ color: 'red' }}>*</span>
            <div className="app__flex">
              <input className="p-text" type="email" placeholder="Email" id="email" name="email" value={email} onChange={handleChangeInput} required />
            </div>
          </label>
          <label htmlFor="nationality">
            Nationality <span style={{ color: 'red' }}>*</span>
            <div className="app__flex">
              <select id="nationality" name="nationality" value={formData.nationality} onChange={handleChangeInput} required>
                <option value="">Select Nationality</option>
                {countryList.map((country, index) => (
                  <option key={index} value={country.name}>{country.name}</option>
                ))}
              </select>
            </div>
          </label>
          <label htmlFor="countryOfResidence">
            Country of Residence <span style={{ color: 'red' }}>*</span>
            <div className="app__flex">
              <select id="countryOfResidence" name="countryOfResidence" value={formData.countryOfResidence} onChange={handleChangeInput} required>
                <option value="">Select Country</option>
                {countryList.map((country, index) => (
                  <option key={index} value={country.name}>{country.name}</option>
                ))}
              </select>
            </div>
          </label>
          <label htmlFor="phoneNumber">
            Phone Number <span style={{ color: 'red' }}>*</span>
            <div className="app__flex">
              <input
                className="p-text"
                type="tel"
                placeholder="Phone Number French or Lebanese Phone Number with +961 or +33"
                id="phoneNumber"
                name="phoneNumber"
                value={phoneNumber}
                onChange={handleChangeInput}
                required
                pattern="(\+961 ?\d{1,8}|\+33 ?\d{1,10})"
                title="Enter a phone number in Lebanese (+961) or French (+33) format"
              />
            </div>
          </label>
          <label htmlFor="university">
            University <span style={{ color: 'red' }}>*</span>
            <div className="app__flex">
              <input className="p-text" type="text" placeholder="University" id="university" name="university" value={university} onChange={handleChangeInput} maxLength="100" required />
            </div>
          </label>
          <label htmlFor="major">
            Major <span style={{ color: 'red' }}>*</span>
            <div className="app__flex">
              <input className="p-text" type="text" placeholder="Major" id="major" name="major" value={major} onChange={handleChangeInput} required maxLength="100" />
            </div>
          </label>
          <label htmlFor="essay">
            What matters the most to you and why? (no chat gpt)<span style={{ color: 'red' }}>*</span>
            <div className="app__flex">
              <textarea
                className="p-text"
                placeholder="Your Essay (max 1000 characters)"
                id="essay"
                name="essay"
                value={essay}
                onChange={handleChangeInput}
                maxLength="1000"
                required
              />
            </div>
          </label>
          <label htmlFor="motivation">
            Why are you joining this program? <span style={{ color: 'red' }}>*</span>
            <div className="app__flex">
              <textarea
                className="p-text"
                placeholder="Your motivation (max 1000 characters)"
                id="motivation"
                name="motivation"
                value={motivation}
                onChange={handleChangeInput}
                maxLength="1000"
                required
              />
            </div>
          </label>
          <label htmlFor="cv">
            CV <span style={{ color: 'red' }}>*</span>
            <div className="app__flex">
              <input
                type="file"
                id="cv"
                name="cv"
                onChange={(e) => setFormData({ ...formData, cv: e.target.files[0] })}
                required
                accept=".pdf,.doc,.docx"
              />
            </div>
          </label>
          <label htmlFor="otherDocument">
            Other Document
            <div className="app__flex">
              <input
                type="file"
                id="otherDocument"
                name="otherDocument"
                onChange={(e) => setFormData({ ...formData, otherDocument: e.target.files[0] })}
                accept=".pdf,.doc,.docx"
              />
            </div>
          </label>
          <button type="button" className="p-text" onClick={handleSubmit}>{!loading ? 'Submit Application' : 'Sending...'}</button>
        </div>
      ) : (
        <div>
          <h3 className="head-text">
            Thank you !
          </h3>
        </div>
      )}
    </>
  );
};

export default AppWrap(
  MotionWrap(Inscription, 'app__inscription'),
  'inscription',
  'app__whitebg',
);
